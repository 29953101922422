<template>
  <section id="toxicology-add-test-block">
    <div class="select-my-test-profiles"
     v-if="hasPermission('test_profiles', 'view')">
      <h4 class="title">My Test Profiles</h4>
      <div class="form-element search-with-filter-input">
        <el-select
          v-model="selectedTestProfile"
          multiple
          placeholder="Select"
          value-key="code"
          @change="handleTestProfiles"
        >
          <el-option
            v-for="(item, index) in TestProfiles"
            :key="index"
            :label="item.title"
            :value="item._id"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <el-tabs v-model="activeToxicologyTab">
      <el-tab-pane name="screening" v-if="showScreening">
        <template #label>
          <el-checkbox
            class="position-relative-top-3"
            :model-value="
              isMultiCheckAll('TOXICOLOGY', ['SCREENING', 'SCREENING_VALIDITY'])
            "
            @change="
              toggleCheckbox(
                'screening',
                ['SCREENING', 'SCREENING_VALIDITY'],
                true,
                $event
              )
            "
          ></el-checkbox>
          <span class="ml-10">Screening</span>
        </template>

        <ul class="add-tests-selection">
          <li
            class="each-test"
            v-for="(category, index) of getCaseCategoryBasedData(
              'TOXICOLOGY',
              ['SCREENING', 'SCREENING_VALIDITY'],
              'screening'
            )"
            :key="index"
          >
            <el-collapse v-model="activeNameCollapseScreening" accordion>
              <el-collapse-item :name="index">
                <template #title>{{ category.category }}</template>
                <el-checkbox
                  class="test-category"
                  :model-value="
                    isCheckAll(category.case_type_code, category.category_code)
                  "
                  @change="
                    checkAll(
                      $event,
                      category.case_type_code,
                      category.category_code,
                      category.category
                    )
                  "
                  >All
                </el-checkbox>

                <el-checkbox-group v-model="panelsTestsData.selectedTestIds">
                  <div
                    v-for="(item, index) of getCategoryWiseTestsData(
                      category.case_type_code,
                      category.category_code
                    )"
                    :key="index"
                  >
                    <div class="each-check-box">
                      <el-checkbox :label="item._id" :value="item">{{
                        item.name
                      }}</el-checkbox>
                    </div>
                  </div>
                </el-checkbox-group>
              </el-collapse-item>
            </el-collapse>
          </li>
        </ul>
      </el-tab-pane>
      <el-tab-pane name="confirmation" v-if="showConfirmation">
        <template #label>
          <el-checkbox
            class="position-relative-top-3"
            :model-value="
              isMultiCheckAll(
                'TOXICOLOGY',
                ['SCREENING', 'ETG/ETS', 'SCREENING_VALIDITY','D_L_Isomer'],
                false
              )
            "
            @change="
              toggleCheckbox(
                'confirmation',
                ['SCREENING', 'ETG/ETS', 'SCREENING_VALIDITY','D_L_Isomer'],
                false,
                $event
              )
            "
          ></el-checkbox>
          <span class="ml-10">Confirmation</span>
        </template>

        <ul class="add-tests-selection">
          <li
            class="each-test"
            v-for="(category, index) of getCaseCategoryBasedData(
              'TOXICOLOGY',
              ['SCREENING', 'ETG/ETS', 'SCREENING_VALIDITY','D_L_Isomer'],
              'confirmation',
              false
            )"
            :key="index"
          >
            <el-collapse v-model="activeNameCollapse" accordion>
              <el-collapse-item :name="index" class="screening">
                <template #title>
                  <el-checkbox
                    class="el-collapse-checkbox"
                    style="position: relative; top: 2px; padding: 0 10px 0 0"
                    :model-value="
                      isCheckAll(
                        category.case_type_code,
                        category.category_code
                      )
                    "
                    @change="
                      checkAll(
                        $event,
                        category.case_type_code,
                        category.category_code,
                        category.category
                      )
                    "
                  ></el-checkbox>
                  {{ category.category }}</template
                >
                <el-checkbox
                  class="test-category"
                  :model-value="
                    isCheckAll(category.case_type_code, category.category_code)
                  "
                  @change="
                    checkAll(
                      $event,
                      category.case_type_code,
                      category.category_code,
                      category.category
                    )
                  "
                  >All</el-checkbox
                >
                <el-checkbox-group v-model="panelsTestsData.selectedTestIds">
                  <div
                    v-for="(item, index) of getCategoryWiseTestsData(
                      category.case_type_code,
                      category.category_code
                    )"
                    :key="index"
                  >
                    <div class="each-check-box">
                      <el-checkbox :label="item._id" :value="item">{{
                        item.name
                      }}</el-checkbox>
                    </div>
                  </div>
                </el-checkbox-group>
              </el-collapse-item>
            </el-collapse>
          </li>
        </ul>
      </el-tab-pane>
      <el-tab-pane name="etg/ets" v-if="showEtgEts">
        <template #label>
          <el-checkbox
            class="position-relative-top-3"
            :model-value="isMultiCheckAll('TOXICOLOGY', ['ETG/ETS'])"
            @change="toggleCheckbox('ETG/ETS', ['ETG/ETS'], true, $event)"
          ></el-checkbox>
          <span class="ml-10">ETG/ETS</span>
        </template>

        <ul class="add-tests-selection">
          <li
            class="each-test"
            v-for="(category, index) of getCaseCategoryBasedData(
              'TOXICOLOGY',
              ['ETG/ETS'],
              'ETG/ETS'
            )"
            :key="index"
          >
            <el-collapse v-model="activeNameCollapseScreening" accordion>
              <el-collapse-item :name="index">
                <template #title>{{ category.category }}</template>
                <el-checkbox
                  class="test-category"
                  :model-value="
                    isCheckAll(category.case_type_code, category.category_code)
                  "
                  @change="
                    checkAll(
                      $event,
                      category.case_type_code,
                      category.category_code,
                      category.category
                    )
                  "
                  >All
                </el-checkbox>

                <el-checkbox-group v-model="panelsTestsData.selectedTestIds">
                  <div
                    v-for="(item, index) of getCategoryWiseTestsData(
                      category.case_type_code,
                      category.category_code
                    )"
                    :key="index"
                  >
                    <div class="each-check-box">
                      <el-checkbox :label="item._id" :value="item">{{
                        item.name
                      }}</el-checkbox>
                    </div>
                  </div>
                </el-checkbox-group>
              </el-collapse-item>
            </el-collapse>
          </li>
        </ul>
      </el-tab-pane>
      <el-tab-pane name="dl isomer" v-if="showDLIsomer">
        <template #label>
          <el-checkbox
            class="position-relative-top-3"
            :model-value="isMultiCheckAll('TOXICOLOGY', ['D_L_Isomer'])"
            @change="toggleCheckbox('dl isomer', ['D_L_Isomer'], true, $event)"
          ></el-checkbox>
          <span class="ml-10">D & L Isomer</span>
        </template>

        <ul class="add-tests-selection">
          <li class="note-text">
            <span
              >Note : D & L Isomer will be tested if the methamphetamine result
              is positive
            </span>
          </li>
          <li
            class="each-test"
            v-for="(category, index) of getCaseCategoryBasedData(
              'TOXICOLOGY',
              ['D_L_Isomer'],
              'dl isomer'
            )"
            :key="index"
          >
            <el-collapse v-model="activeNameCollapseScreening" accordion>
              <el-collapse-item :name="index">
                <template #title>{{ category.category }}</template>
                <el-checkbox
                  class="test-category"
                  :model-value="
                    isCheckAll(category.case_type_code, category.category_code)
                  "
                  @change="
                    checkAll(
                      $event,
                      category.case_type_code,
                      category.category_code,
                      category.category
                    )
                  "
                  >All
                </el-checkbox>

                <el-checkbox-group v-model="panelsTestsData.selectedTestIds">
                  <div
                    v-for="(item, index) of getCategoryWiseTestsData(
                      category.case_type_code,
                      category.category_code
                    )"
                    :key="index"
                  >
                    <div class="each-check-box">
                      <el-checkbox :label="item._id" :value="item">{{
                        item.name
                      }}</el-checkbox>
                    </div>
                  </div>
                </el-checkbox-group>
              </el-collapse-item>
            </el-collapse>
          </li>
        </ul>
      </el-tab-pane>
    </el-tabs>
  </section>
</template>

<script>
import UserPermissionsHelper from "@/mixins/UserPermissionsHelper";
import AppHelper from "@/mixins/AppHelper";
import { mapGetters } from "vuex";
export default {
  mixins: [UserPermissionsHelper],
  props: [
    "panelTestsData",
    "searchString",
    "selectedPanel",
    "customProfileTest",
    "checklistData",
    "specimenData",
  ],
  data() {
    return {
      activeToxicologyTab: "screening",
      screeningChecked: false,
      confirmationChecked: false,
      etgChecked: false,
      isConfirmationDisabled: false,
      isScreeningDisabled: false,
      isEtgEtsDisabled: false,
      isDlIsomerDisabled: false,
      isOralFluidToxConfirmationDisabled: false,
      activeNameCollapse: "",
      activeNameCollapseScreening: [0, 1],
      activeNameCollapseETG: [0],
      panelsTestsData: "",
      selectedTestProfile: [],
      TestProfiles: [],
      getChecklistData: this.checklistData || "",
      showScreening: false,
      showConfirmation: false,
      showEtgEts: false,
      showDLIsomer: false,
    };
  },
  computed: {
    ...mapGetters("labTests", ["getAllLabTests"]),
    ...mapGetters("testProfiles", ["getCaseTypeTestProfiles"]),
  },
  mounted() {
    try {
      this.panelsTestsData = this.panelTestsData;
      this.selectedTestProfile = this.customProfileTest;
      if(this.isFacilityUser){
          this.$store.dispatch("testProfiles/fetchCaseTypeTestProfile", {
          case_type: "TOXICOLOGY",
        });
      }
      if (this.getCaseTypeTestProfiles && this.getCaseTypeTestProfiles.data) {
        if (this.getChecklistData && this.getChecklistData.length) {
           this.TestProfiles = this.getCaseTypeTestProfiles.data.filter(profile => {
           return this.getChecklistData.every(item => profile.check_list_items.includes(item));
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
    if (this.getChecklistData && this.getChecklistData.length) {
      this.setCheckListData();
    }
  },
  methods: {
    toggleCheckbox(type, category, include = true, check = true) {
      let data = [];

      if (include) {
        data = this.getAllLabTests.filter(
          (el) =>
            el.case_type_code == "TOXICOLOGY" &&
            category.indexOf(el.category_code) != -1
        );
      } else {
        data = this.getAllLabTests.filter(
          (el) =>
            el.case_type_code === "TOXICOLOGY" &&
            category.indexOf(el.category_code) == -1
        );
      }

      if (check) {
        data.map((item) => {
          if (this.panelsTestsData.selectedTestIds.indexOf(item._id) == -1) {
            this.panelsTestsData.selectedTestIds.push(item._id);
          }
        });
      } else {
        check = false;
        data.map((item) => {
          if (this.panelsTestsData.selectedTestIds.indexOf(item._id) != -1) {
            this.panelsTestsData.selectedTestIds.splice(
              this.panelsTestsData.selectedTestIds.indexOf(item._id),
              1
            );
          }
        });
      }
    },
    getCaseCategoryBasedData(caseType, category, type, include = true) {
      let data = "";
      let outputWithFullDetails = [];
      if (this.getAllLabTests) {
        if (include) {
          data = this.getAllLabTests.filter(
            (el) =>
              el.case_type_code &&
              el.case_type_code === caseType &&
              category.indexOf(el.category_code) != -1 &&
              (!this.searchString ||
                el.name
                  .toLowerCase()
                  .includes(this.searchString.toLowerCase()) ||
                el.category
                  .toLowerCase()
                  .includes(this.searchString.toLowerCase()))
          );
        } else {
          data = this.getAllLabTests.filter(
            (el) =>
              el.case_type_code &&
              el.case_type_code === caseType &&
              category.indexOf(el.category_code) == -1 &&
              (!this.searchString ||
                el.name
                  .toLowerCase()
                  .includes(this.searchString.toLowerCase()) ||
                el.category
                  .toLowerCase()
                  .includes(this.searchString.toLowerCase()))
          );
        }

        let flags = [],
          output = [],
          l = data.length,
          i;

        for (i = 0; i < l; i++) {
          if (flags[data[i].category_code]) continue;
          flags[data[i].category_code] = true;
          output.push(data[i].category_code);
          outputWithFullDetails.push(data[i]);
        }
      } else {
        return outputWithFullDetails;
      }
      return outputWithFullDetails;
    },
    getCategoryWiseTestsData(caseType, category) {
      let data = [];
      if (this.searchString) {
        data = this.getAllLabTests.filter(
          (el) =>
            el.case_type_code === caseType &&
            el.category_code === category &&
            (el.name.toLowerCase().includes(this.searchString.toLowerCase()) ||
              el.category
                .toLowerCase()
                .includes(this.searchString.toLowerCase()))
        );
      } else {
        data = this.getAllLabTests.filter(
          (el) =>
            el.case_type_code === caseType && el.category_code === category
        );
      }
      return this.sortByKey(data, "name");
    },
    getCategoriesWiseTestsData(caseType, categories, include = true) {
      let data = [];
      if (this.searchString) {
        data = this.getAllLabTests.filter(
          (el) =>
            el.case_type_code === caseType &&
            ((include && categories.indexOf(el.category_code) != -1) ||
              (!include && categories.indexOf(el.category_code) == -1)) &&
            (el.name.toLowerCase().includes(this.searchString.toLowerCase()) ||
              el.category
                .toLowerCase()
                .includes(this.searchString.toLowerCase()))
        );
      } else {
        data = this.getAllLabTests.filter(
          (el) =>
            el.case_type_code === caseType &&
            ((include && categories.indexOf(el.category_code) != -1) ||
              (!include && categories.indexOf(el.category_code) == -1))
        );
      }

      return this.sortByKey(data, "name");
    },

    checkAll(data, caseType, category, subCategory) {
      let selectedData = this.getCategoryWiseTestsData(caseType, category).map(
        (item) => item._id
      );
      if (data) {
        this.$emit("onCheckedSelection", subCategory);
        for (let i = 0; i < selectedData.length; i++) {
          if (
            this.panelsTestsData.selectedTestIds.indexOf(selectedData[i]) == -1
          ) {
            this.panelsTestsData.selectedTestIds.push(selectedData[i]);
          }
        }
      } else {
        for (let i = 0; i < selectedData.length; i++) {
          let index = this.panelsTestsData.selectedTestIds.indexOf(
            selectedData[i]
          );

          if (index > -1) {
            this.panelsTestsData.selectedTestIds.splice(index, 1);
          }
        }
        this.$emit("onUncheckedSelection", subCategory);
      }
    },
    isCheckAll(caseType, category) {
      let selectedData = this.getCategoryWiseTestsData(caseType, category).map(
        (item) => item._id
      );

      return selectedData.every((item) => {
        return this.panelsTestsData.selectedTestIds.indexOf(item) !== -1;
      });
    },
    isMultiCheckAll(caseType, categories, include = true) {
      let selectedData = this.getCategoriesWiseTestsData(
        caseType,
        categories,
        include
      ).map((item) => item._id);

      return selectedData.every((item) => {
        return this.panelsTestsData.selectedTestIds.indexOf(item) !== -1;
      });
    },
    handleTestProfiles() {
      this.$emit("test-profiles-selected", this.selectedTestProfile);
      if (this.selectedTestProfile && this.selectedTestProfile.length) {
        let retrievedLabProfiles = [];
        let selectedTests = [];

        for (const id of this.selectedTestProfile) {
          const matchingProfile = this.TestProfiles.find(
            (profile) => profile._id === id
          );

          if (matchingProfile) {
            retrievedLabProfiles.push(matchingProfile);
            selectedTests = selectedTests.concat(matchingProfile.lab_test_ids);
          }
        }

        // getting unique tests and removing non selected tests

        if (selectedTests.length) {
          this.removeAll();
          this.panelsTestsData.selectedTestIds = this.getUniquIds([
            ...this.panelsTestsData.selectedTestIds,
            ...selectedTests,
          ]);
        }
      } else {
        this.removeAll();
        this.panelsTestsData.selectedCoriellPanels =
          this.panelsTestsData.selectedCoriellPanels.filter(
            (x) => x.case_type != "TOXICOLOGY"
          );
      }
    },
    getUniquIds(ids) {
      return Array.from(new Set(ids));
    },
    removeAll() {
      let toxPanelTests = [].concat.apply(
        [],
        this.TestProfiles.map((e) => e.lab_test_ids)
      );
      this.panelsTestsData.selectedTestIds =
        this.panelsTestsData.selectedTestIds.filter(
          (x) => toxPanelTests.indexOf(x) == -1
        );
    },
    setCheckListData() {
      if (
        this.getChecklistData &&
        this.getChecklistData.length &&
        this.getChecklistData.length == 1 &&
        this.specimenData == "Oral"
      ) {
        let options = this.getChecklistData.toString();
        switch (options) {
          // 1
          case "Confirm Tests Selected Below":
            this.toggleCheckbox(
              "TOXICOLOGY",
              ["ORAL_FLUID_TOX_CONFIRMATION"],
              true
            );
            this.activeToxicologyTab = "oral fluid";
            this.ShowOralFluid = true;
            break;
          default:
            this.toggleCheckbox(false);
        }
      }
      if (
        this.getChecklistData &&
        this.getChecklistData.length &&
        this.getChecklistData.length == 1 &&
        this.specimenData == "Urine"
      ) {
        let options = this.getChecklistData.toString();
        switch (options) {
          // 1
          case "Screen and Confirm Tests Selected Below":
            this.toggleCheckbox(
              "TOXICOLOGY",
              ["SCREENING", "SCREENING_VALIDITY"],
              true
            );
            this.activeToxicologyTab = "screening";
            this.showScreening = true;
            this.showConfirmation = true;
            this.showEtgEts = true;
            this.showDLIsomer = true;
            break;
          // 2
          case "Screen and Confirm All Negative and Positive Results":
            this.toggleCheckbox(
              "TOXICOLOGY",
              ["ETG/ETS", "D_L_Isomer", "ORAL_FLUID_TOX_CONFIRMATION"],
              false
            );
            this.activeToxicologyTab = "screening";
            this.showScreening = true;
            this.showConfirmation = true;
            this.showEtgEts = true;
            this.showDLIsomer = true;
            break;
          // 3
          case "Screen Only":
            this.toggleCheckbox(
              "TOXICOLOGY",
              ["SCREENING", "SCREENING_VALIDITY"],
              true
            );
            this.activeToxicologyTab = "screening";
            this.showScreening = true;
            break;
          // 4
          case "Confirm All Negative and Positive Results":
            this.toggleCheckbox(
              "TOXICOLOGY",
              [
                "SCREENING",
                "SCREENING_VALIDITY",
                "ETG/ETS",
                "D_L_Isomer",
                "ORAL_FLUID_TOX_CONFIRMATION",
              ],
              false
            );
            this.activeToxicologyTab = "confirmation";
            this.showConfirmation = true;
            this.showEtgEts = true;
            this.showDLIsomer = true;
            break;
          // 5
          case "Confirm Tests Selected Below":
            this.activeToxicologyTab = "confirmation";
            this.showConfirmation = true;
            this.showEtgEts = true;
            this.showDLIsomer = true;
            break;
          // 6
          case "Screen Reflex to Confirmation":
            this.toggleCheckbox(
              "TOXICOLOGY",
              ["SCREENING", "SCREENING_VALIDITY"],
              true
            );
            this.activeToxicologyTab = "screening";
            this.showScreening = true;
            break;
          // 7
          case "Confirm All Prescribed Medications of Interest":
            this.toggleCheckbox(
              "TOXICOLOGY",
              ["SCREENING", "SCREENING_VALIDITY"],
              true
            );
            this.activeToxicologyTab = "screening";
            this.showScreening = true;
        }
      }
      if (
        this.getChecklistData &&
        this.getChecklistData.length == 2 &&
        this.specimenData === "Urine" &&
        this.getChecklistData.includes("Screen Reflex to Confirmation") &&
        this.getChecklistData.includes(
          "Confirm All Prescribed Medications of Interest"
        )
      ) {
        this.toggleCheckbox(
          "TOXICOLOGY",
          ["SCREENING", "SCREENING_VALIDITY"],
          true
        );
        this.activeToxicologyTab = "screening";
        this.showScreening = true;
      }
      if (
        this.getChecklistData &&
        this.getChecklistData.length == 3 &&
        this.specimenData === "Urine" &&
        this.getChecklistData.includes("Screen Reflex to Confirmation") &&
        this.getChecklistData.includes(
          "Confirm All Prescribed Medications of Interest"
        ) &&
        this.getChecklistData.includes("Confirm Tests Selected Below")
      ) {
        this.toggleCheckbox(
          "TOXICOLOGY",
          ["SCREENING", "SCREENING_VALIDITY"],
          true
        );
        this.activeToxicologyTab = "screening";
        this.showScreening = true;
        this.showConfirmation = true;
        this.showEtgEts = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.all-tests {
  display: flex;
  flex-wrap: wrap;
  .each-test {
    width: 33.33%;
    .each-check-box {
      .el-checkbox {
        display: flex;
        align-items: flex-start;
      }
      .el-checkbox__label {
        white-space: normal;
      }
      &:not(:last-child) {
        margin-bottom: 10px !important;
      }
    }
  }
}
</style>
<style lang="scss">
#toxicology-add-test-block {
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  .el-tabs__nav-scroll {
    margin: 0 20px;
    overflow: auto;
  }
  .el-tabs__item {
    padding: 0 9px !important;
  }
  .el-tabs__nav-prev,
  .el-tabs__nav-next {
    display: none;
    font-size: 20px;
    background: #27347f;
    color: #ffffff;
    font-weight: 600;
    height: 100%;
  }
}
#pane-screening {
  .el-collapse-item__content {
    max-height: 350px;
  }
}
</style>
